<template>
  <div
    class="reservation"
    v-if="!isLoading"
    @click="hidePopover"
  >
    <Header
      :title="$t(`stepTitles.${curStep}`)"
      :isCanGoPrev="STEPS_WITH_BACK_BUTTON.includes(curStep) && curStep != minStep"
      :isShowAuthPopover="isShowAuthPopover"
      :onGoPrev="goPrev"
    />

    <template
      v-if="reservation"
    >
      <CompanyCard
        v-if="STEPS_WITH_COMPANY_CARD.includes(curStep)"
        :point="reservation.point"
      />

      <PointsStep
        v-if="curStep === 1"
        :reservation="reservation"
        :onSelectPoint="onSelectPoint"
      />

      <ServicesStep
        v-if="curStep === 2"
        :reservation="reservation"
        :onSelectServices="onSelectServices"
      />

      <ResourcesStep
        v-if="curStep === 3"
        :reservation="reservation"
        :onSelectResource="onSelectResource"
        :onSelectResourceAndDateTime="onSelectResourceAndDateTime"
      />

      <DateTimeStep
        v-if="curStep === 4"
        :reservation="reservation"
        :onSelectDateTime="onSelectDateTime"
      />

      <ContactsStep
        v-if="curStep === 5"
        :reservation="reservation"
        :isEmailRequired="isEmailRequired"
        :onContactsSubmit="onContactsSubmit"
      />

      <SummaryStep
        v-if="curStep === 6"
        :reservation="reservation"
        :onReservationSubmit="onReservationSubmit"
      />

      <PaymentStep
        v-if="curStep === 7"
        :reservation="reservation"
      />

      <YaMetrika
        v-if="yaMetrikaId"
        :yaMetrikaId="yaMetrikaId"
      />
    </template>
  </div>
</template>

<script>
import CompanyCard from '@/ui/components/CompanyCard'
import ContactsStep from '@/ui/components/steps/5.ContactsStep'
import DateTimeStep from '@/ui/components/steps/4.DateTimeStep'
import Header from '@/ui/components/Header'
import PaymentStep from '@/ui/components/steps/7.PaymentStep'
import PointsStep from '@/ui/components/steps/1.PointsStep'
import ResourcesStep from '@/ui/components/steps/3.ResourcesStep'
import ServicesStep from '@/ui/components/steps/2.ServicesStep'
import SummaryStep from '@/ui/components/steps/6.SummaryStep'
import YaMetrika from '@/ui/components/YaMetrika'
import authService from '@/services/AuthService'
import clientService from '@/services/ClientService'
import pointService from '@/services/PointService'
import reservationService from '@/services/ReservationService'
import serviceService from '@/services/ServiceService'
import settingsService from '@/services/SettingsService'
import vendorStore from '@/stores/VendorStore'

const STEPS_WITH_COMPANY_CARD = [2, 3, 4, 5, 6, 7];
const STEPS_WITH_BACK_BUTTON = [2, 3, 4, 5, 6];

export default {
  name: 'BookingPage',
  components: {
    CompanyCard,
    ContactsStep,
    DateTimeStep,
    Header,
    PaymentStep,
    PointsStep,
    ResourcesStep,
    ServicesStep,
    SummaryStep,
    YaMetrika,
  },

  data() {
    return {
      isLoading: true,
      STEPS_WITH_COMPANY_CARD,
      STEPS_WITH_BACK_BUTTON,
      minStep: 1,
      maxStep: 7,
      curStep: 1,
      reservation: null,
      isEmailRequired: true,
      isSkipResource: false,
      yaMetrikaId: null,
      uiParams: {
        color: "#FFFFFF",
        backgroundColor: "#CC0000",
      },
      isShowAuthPopover: !authService.isAuthenticated()
    }
  },

  async created() {
    this.isLoading = true;
    this.parseUrlParams();
    await this.loadSettings();
    this.createBlankReservation();
    this.isLoading = false;
  },

  methods: {
    parseUrlParams() {
      // Берем id из урлы и кладем в стор для подставновки в header запросов
      // Делаем до рендера чтобы успеть до первого запроса
      if (this.$route.params.publicApiKey) {
        vendorStore.setPublicAPIKey(this.$route.params.publicApiKey);
      }

      // Если параметром передан филиал, пропускаем шаг карты
      if (this.$route.query.pointId) {
        this.pointId = Number(this.$route.query.pointId);
        this.minStep = 2;
        this.curStep = 2;
      }
    },

    async loadSettings() {
      await settingsService.loadVendorSettings(vendorStore.getPublicAPIKey());

      this.isEmailRequired = settingsService.getIsEmailRequired();
      this.isSkipResource = settingsService.getIsSkipResource();
      this.yaMetrikaId = settingsService.getYaMetrikaId();
    },

    async createBlankReservation() {
      // Создаем объект записи, который будем наполнять по шагам
      let reservation = await reservationService.createNewReservation();

      if (this.pointId) {
        try {
          reservation.point = await pointService.getPoint(this.pointId);
        } catch (error) {
          this.$toast.open({
            message: error.message,
            type: 'error'
          });
        }
      }

      this.reservation = reservation;
    },

    hidePopover() {
      this.isShowAuthPopover = false;
    },

    goToStep(step) {
      this.curStep = step;
    },

    goNext() {
      if (this.curStep < this.maxStep) {
        this.curStep += 1;
      }
    },

    goPrev() {
      if (this.curStep > this.minStep) {
        if (this.curStep == 4 && this.isSkipResource) {
          // Если стоит пропуск выбора мастера, то с выбора даты переходим на услуги
          this.curStep = 2
        } else {
          this.curStep -= 1;
        }
      }
    },

    async onSelectPoint(point) {
      // При выборе новой точки сбрасываем запись, т.к. она могла быть заполнена данными для другой
      this.reservation = await reservationService.createNewReservation();
      this.reservation.point = point;
      this.goNext();
    },

    onSelectServices(services) {
      this.reservation.reservation_services = serviceService.createReservationServicesFromSerivices(services);
      if (this.isSkipResource) {
        this.goToStep(4);
      } else {
        this.goNext();
      }
    },

    onSelectResource(resource) {
      if (resource.id != 'any') {
        this.reservation.resource = resource;
      }
      this.goNext();
    },

    onSelectResourceAndDateTime(resource, dateTime) {
      if (resource.id != 'any') {
        this.reservation.resource = resource;
      }
      this.reservation.start_date = dateTime;
      this.goToStep(5);
    },

    onSelectDateTime(dateTime) {
      this.reservation.start_date = dateTime;
      this.goNext();
    },

    onContactsSubmit(clientName, clientPhone, clientEmail, clientNote) {
      this.reservation.client_note = clientNote;
      this.reservation.client.name = clientName;
      this.reservation.client.phone = clientPhone;
      this.reservation.client.email = clientEmail;
      clientService.saveToLocalStorage(this.reservation.client);
      this.goNext();
    },

    onReservationSubmit(reservation, isOnlinePaymentRequired) {
      this.reservation.id = reservation.id;
      this.reservation.code = reservation.code;
      this.reservation.total_cost = reservation.total_cost;

      if (isOnlinePaymentRequired) {
        this.goNext();
      } else {
        this.$router.push({
        name: 'ReservationSuccess',
        params: {
          reservationCode: reservation.code
        }
      })
      }
    }
  }
}
</script>

<style lang="scss">
.reservation {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>