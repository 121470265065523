<template>
  <div
    class="inline-calendar"
    :key="$i18n.locale"
  >
    <div
      class="month"
      v-for="monthKey in Object.keys(datesByMonths)"
      :key="monthKey"
    >
      <div class="month-name">
        {{capitalize(moment.months(parseInt(monthKey.split('-')[1])))}}
      </div>

      <div class="dates">
        <div
          class="item"
          :class="{ 'selected': selectedDate == date}"
          v-for="date in datesByMonths[monthKey]"
          :key="date"
          @click="() => onDateSelect(date)"
        >
          <div class="number">
            <div class="name">{{moment(date).format('D')}}</div>

            <div
              class="is-available"
              v-if="availableDates.includes(date)"
            ></div>
          </div>
          <div class="day">
            {{moment(date).format('dd')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { capitalize } from '@/utils/Helpers';

const DISPLAYED_DAYS = 30 * 6; // 6 месяцев


export default {
  name: 'InlineCalendar',
  props: {
    availableDates: Array,
    selectedDate: String,
    isCanGoPrev: Boolean,
    onDateSelect: Function
  },

  data() {
    return {
      moment,
      capitalize,
      startDate: moment(new Date()).format('YYYY-MM-DD'),
    }
  },

  computed: {
    datesByMonths() {
      let datesByMonths = {};
      for (var i = 0; i < DISPLAYED_DAYS; i++) {
        let date = moment(this.startDate).add(i, 'days');
        let month = date.month();
        let year = date.year();

        let monthKey = `${year}-${month}`;

        if (!datesByMonths[monthKey]) {
          datesByMonths[monthKey] = [];
        }

        datesByMonths[monthKey].push(date.format('YYYY-MM-DD'));
      }

      return datesByMonths;
    }
  },

  watch: {
    // Фокусируемся на дате при выборе
    selectedDate() {
      this.$nextTick(() => {
        this.scrollToSelected();
      });
    }
  },

  methods: {
    scrollToSelected() {
      const selectedEl = this.$el.querySelector('.dates > .item.selected');
      if (selectedEl) {
        selectedEl.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center"
        });
      }
    }
  }
}
</script>

<style lang="scss">
.inline-calendar {
  background-color: white;
  padding: 10px;
  display: flex;
  overflow: scroll;

  .month {
    position: relative;
    margin-right: 15px;

    .month-name {
      font-weight: bold;
      padding-left: 15px;
    }

    .dates {
      display: flex;

      & > .item {
        text-align: center;
        margin: 5px;
        cursor: pointer;

        .number {
          width: 33px;
          height: 33px;
          line-height: 1em;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;

          .name {
            font-size: 15px;
          }

          .is-available {
            position: absolute;
            bottom: 4px;
            width: 3px;
            height: 3px;
            background-color: $primary;
            border-radius: 50%;
          }
        }

        .day {
          font-size: 12px;
          color: $grayText;
        }

        &.selected {
          .number {
            background-color: $primary;
            border-radius: 50%;
            color: white;

            .is-available {
              background-color: white;
            }
          }
        }
      }
    }
  }
}
</style>