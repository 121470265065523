<template>
  <div class="signup-page">
    <Header
      :title="$t('cmp.signup.header')"
      :isCanGoPrev="isCanGoPrev"
      :onGoPrev="goPrev"
    />

    <SignUp
      :onSignIn="onSignIn"
    />

  </div>
</template>

<script>
import Header from '@/ui/components/Header'
import SignUp from '@/ui/components/profile/SignUp'


export default {
  name: 'SignUpPage',
  components: {
    Header,
    SignUp,
  },

  data() {
    return {
      isCanGoPrev: window.history.length > 2
    }
  },

  methods: {
    goPrev() {
      this.$router.back();
    },

    onSignIn() {
      this.$router.replace('/profile')
    }
  }
}
</script>

<style lang="scss">
</style>