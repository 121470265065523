import { createI18n } from 'vue-i18n'

import ru from "./locale/ru.json";
import en from "./locale/en.json";
import tr from "./locale/tr.json";
import sr from "./locale/sr.json";


const i18n = createI18n({
  locale: 'ru',
  fallbackLocale: 'en',
  messages: {
    ru,
    en,
    tr,
    sr,
  }
});

export default i18n;