<template>
  <div class="fail-step">
    <div class="center-block">
      <img class="fail-icon" src="@/ui/assets/icons/fail-big.svg" />

      <div class="title">{{ $t('cmp.failStep.title') }}</div>
      <div class="description" v-html="$t('cmp.failStep.description', {payUrl})">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FailStep',

  props: {
    payUrl: String
  }
}
</script>

<style lang="scss">
.fail-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  .center-block {
    padding: 150px 40px;
    text-align: center;

    .fail-icon {
      margin-bottom: 10px;
    }

    .title {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }
}
</style>