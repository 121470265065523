<template>
  <div
    class="company-card"
    v-if="point"
  >
    <img
      class="logo"
      v-if="point.vendor.photo?.thumb_src"
      :src="point.vendor.photo.thumb_src"
    />

    <div class="info">
      <div class="name">
        {{point.name}}
      </div>

      <div class="address">
        {{point.address}}
        <div class="address-overview">
          {{point.address_overview}}
        </div>
        <a
          v-if="point.contact_phone"
          class="phone"
          :href="`tel:${point.contact_phone}`"
        >
          {{point.contact_phone}}
        </a>
      </div>

      <TubeStation
        :tubeStation="point.tube_station"
      />

    </div>
  </div>
</template>

<script>
import TubeStation from '@/ui/components/TubeStation'


export default {
  name: 'CompanyCard',
  props: {
    point: Object,
  },
  components: {
    TubeStation,
  },
}
</script>

<style lang="scss">
.company-card {
  padding: 15px;
  background-color: $primaryLight;
  display: flex;
  color: white;

  .logo {
    margin-right: 20px;
    max-width: 150px;
    max-height: 90px;
    border-radius: 10px;
  }

  .info {
    .name {
      font-size: 18px;
    }

    .address {
      color: #C5B5FF;
      font-size: 14px;
      margin-bottom: 5px;

      .phone {
        color: #C5B5FF;
      }
    }
  }
}
</style>
