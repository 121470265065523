import Api from '@/utils/Api';
import ServiceCategoryModel from '@/models/ServiceCategoryModel';

const serviceApi = {
  async getCategoriesWithServices(pointId) {
    const response = await Api.get(`/point-services?point_id=${pointId}`);
    return response.data['service-categories'].map((scPayload) => {
      return new ServiceCategoryModel().pushPayload(scPayload);
    });
  }
}

export default serviceApi;