<template>
  <div class="contacts-step">
    <div class="form-wrapper">
      <div class="form-group">
        <label class="form-label required">{{ $t('cmp.contactsStep.name') }}</label>
        <input
          class="form-control"
          v-model="clientName"
        />
        <div
          class="form-invalid-text"
          v-if="errors['clientName']"
        >
          {{errors['clientName']}}
        </div>
      </div>

      <div class="form-group">
        <label class="form-label required">{{ $t('cmp.contactsStep.phone') }}</label>
        <input
          class="form-control"
          type="tel"
          v-model="clientPhone"
          v-maska="'+# (###) ###-##-####'"
        />
        <div
          class="form-invalid-text"
          v-if="errors['clientPhone']"
        >
          {{errors['clientPhone']}}
        </div>
      </div>

      <div class="form-group">
        <label
          class="form-label"
          :class="{'required': isEmailRequired}"
        >
          Email
        </label>
        <input
          class="form-control"
          type="email"
          v-model="clientEmail"
        />
        <div class="form-text">{{ $t('cmp.contactsStep.emailDescr') }}</div>
        <div
          class="form-invalid-text"
          v-if="errors['clientEmail']"
        >
          {{errors['clientEmail']}}
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">{{ $t('cmp.contactsStep.comments') }}</label>
        <input
          class="form-control"
          v-model="clientNote"
        />
      </div>
    </div>

    <BottomBar>
      <div class="row">
        <div class="col d-flex">
        </div>
        <div class="col-md-4 d-flex align-items-center">
          <Button
            :text="$t('buttons.next')"
            :action="() => submit()"
          />
        </div>
      </div>
    </BottomBar>
  </div>
</template>

<script>
import BottomBar from '@/ui/components/BottomBar'
import Button from '@/ui/components/Button'
import { PHONE_REGEX, EMAIL_REGEX } from '@/utils/Constants'

export default {
  name: 'ContactsStep',
  props: {
    reservation: Object,
    isEmailRequired: Boolean,
    onContactsSubmit: Function,
  },
  components: {
    BottomBar,
    Button,
  },

  data() {
    return {
      clientName: this.reservation.client?.name || '',
      clientPhone: this.reservation.client?.phone || this.reservation.point.country.phone_code,
      clientEmail: this.reservation.client?.email || '',
      clientNote: this.reservation.client_note || '',
      errors: {}
    }
  },

  methods: {
    submit() {
      this.errors = {};

      // Провека имени
      if (!this.clientName.length) {
        this.errors['clientName'] = 'Поле обязательное';
      }

      // Провека телефона
      if (!this.clientPhone.length) {
        this.errors['clientPhone'] = 'Поле обязательное';
      } else if (!this.clientPhone.match(PHONE_REGEX)) {
        this.errors['clientPhone'] = 'Введите правильный номер телефона';
      }

      // Проверка почты
      if (this.isEmailRequired && !this.clientEmail.length) {
        this.errors['clientEmail'] = 'Поле обязательное';
      } else if (this.clientEmail.length && !this.clientEmail.match(EMAIL_REGEX)) {
        this.errors['clientEmail'] = 'Введите правильный адрес почты';
      }

      if (Object.keys(this.errors).length > 0) {
        return;
      }

      this.onContactsSubmit(
        this.clientName,
        this.clientPhone,
        this.clientEmail,
        this.clientNote,
      );
    }
  }
}
</script>

<style lang="scss">
.contacts-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  .form-wrapper {
    padding: 20px;
    margin-bottom: 20px;

    .form-invalid-text {
      font-size: 14px;
      color: $redText;
      margin-top: 3px;
    }
  }
}
</style>