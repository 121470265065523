<template>
  <div class="content">
    <div class="page">
      <router-view/>
    </div>

    <Footer />
  </div>
</template>

<script>
  import Footer from '@/ui/components/Footer';

  export default {
    name: 'App',
    components: {
      Footer,
    },
  }
</script>

<style lang="scss">
@import "@/ui/assets/styles/reset.scss";
@import "@/ui/assets/styles/base.scss";
@import "@/ui/assets/styles/museoSans.scss";
@import "@/ui/assets/styles/bootstrap-grid.scss";
@import "@/ui/assets/styles/bootstrap-form.scss";
@import "@/ui/assets/styles/bootstrap-dropdown.scss";

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .page {
    display: flex;
    flex-direction: column;
    margin: 5px;
    max-width: 600px;
    width: 100%;
    min-height: 700px;
    background-color: $pageBackground;
    border-radius: $pageBorderRadious;
    box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 15%);
    border:1px solid $border;
  }
}
</style>
