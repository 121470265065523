<template>
  <div class="payment-step">
    <div class="payment-container">
      <div class="price-description">
        К оплате:
      </div>
      <div class="price">
        {{reservation.total_cost}} {{currencySign}}
      </div>

      <a
        v-bind:href="getPaymentUrl()"
        class="button solid"
      >
        Оплатить
      </a>
    </div>
  </div>
</template>

<script>
import reservationService from '@/services/ReservationService'

export default {
  name: 'PaymentStep',

  props: {
    reservation: Object
  },


  data() {
    return {
      currencySign: this.reservation.point.country.currency.sign
    }
  },

  methods: {
    getPaymentUrl() {
      return reservationService.getPaymentUrl(this.reservation.code)
    }
  }
}
</script>

<style lang="scss">
.payment-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .payment-container {
    min-width: 300px;

    .price {
      font-size: 50px;
      margin-bottom: 60px;
    }
  }
}
</style>