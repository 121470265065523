<template>
  <div class="locale-selector">
    <div class="dropdown">
      <button
        class="dropdown-toggle"
        @click="toggleMenu"
      >
        {{localeName}}
        <img src="@/ui/assets/icons/chevron-down-small.svg" />
      </button>
      <ul
        class="dropdown-menu"
        :class="{ show: isMenuOpen }"
      >
        <li
          v-for="(value, key) in LOCALES"
          :key="key"
          @click="setLocale(key)"
        >
          <a class="dropdown-item" href="#">{{value}}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import i18n from "@/ui/i18n"
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/en-gb'
import 'moment/locale/tr'

import settingsService from '@/services/SettingsService'
import { LOCALES } from '@/utils/Constants'

export default {
  name: 'LocaleSelector',

  data() {
    return {
      isMenuOpen: false,
      LOCALES,
      curLocale: settingsService.getLocale()
    }
  },

  created() {
    this.applyLocale(settingsService.getLocale());
  },

  computed: {
    localeName() {
      return LOCALES[this.curLocale];
    }
  },

  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

    setLocale(locale) {
      settingsService.setLocale(locale);
      this.toggleMenu();
      this.applyLocale(locale);
    },

    applyLocale(locale) {
      this.curLocale = locale;
      moment.locale(locale);
      i18n.global.locale = locale;
    }
  }
}
</script>

<style lang="scss">
.locale-selector {

  .dropdown {
    .dropdown-toggle {
      border: none;
      background: transparent;
      color: white;
      cursor: pointer;
    }
  }
}
</style>