import axios from 'axios';
import vendorStore from '@/stores/VendorStore';
import userSessionStorage from '@/stores/UserSessionStorage';

const API_HOST = process.env.VUE_APP_API_HOST;

class HttpError extends Error {
  constructor(message, statusCode, response) {
    super(message);

    this.message = message;
    this.name = "HttpError";
    this.statusCode = statusCode;
    this.response = response;
  }
}


export default class Api {

  static async getHeaders(method) {
    const vendorApiKey = {
      'VendorPublicAPIKey': vendorStore.getPublicAPIKey()
    }

    let session = userSessionStorage.getSession();
    const userJWT = session.jwt ? {
      'UserJWT': session.jwt
    } : {}

    const contentType = ['put', 'post', 'delete'].includes(method) ?
      { 'Content-Type': 'application/json' } : {};

    return {
      ...vendorApiKey,
      ...userJWT,
      ...contentType
    }
  }

  static get(url, config) {
    return this.doRequest(url, config, 'get');
  }

  static put(route, config) {
    return this.doRequest(route, config, 'put')
  }

  static post(route, config) {
    return this.doRequest(route, config, 'post')
  }

  static delete(route, config) {
    return this.doRequest(route, config, 'delete')
  }

  static async doRequest(url, conf, method) {
    const config = Object.assign({
      headers: await this.getHeaders(method),
      method: method,
      baseURL: API_HOST,
      url: url
    }, conf);

    return new Promise((resolve, reject) => {
      axios(config)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        if (error.response.status >= 500) {
          reject(new HttpError(
            'Unknown Server Error',
            error.response.status,
            error.response
          ));
        } else if (error.response.status == 422) {
          reject(new HttpError(
            'Ошибки в заполнения формы: ' + JSON.stringify(error.response.data.errors),
            error.response.status,
            error.response
          ));
        } else {
          reject(new HttpError(
            error.response.data.title,
            error.response.status,
            error.response
          ));
        }
      });
    });
  }
}