const PHONE_REGEX = /^[+]?[0-9]{1}?[\s]?[(]?[0-9]{3}[)]?[\s]?[0-9]{3}[-?][0-9]{2}[-?][0-9]{2,4}$/gm;
const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

const LOCALES = {
  ru: 'РУ',
  en: 'EN',
  tr: 'TR',
  sr: 'SR',
};

const DEFAULT_LOCALE = 'ru';

export {
  PHONE_REGEX,
  EMAIL_REGEX,
  LOCALES,
  DEFAULT_LOCALE
}