import pointApi from "@/apis/PointApi";

const pointService = {
  async getPoints() {
    return await pointApi.getPoints();
  },

  async getPoint(pointId) {
    return await pointApi.getPoint(pointId);
  }
}

export default pointService;