<template>
  <div class="signin-page">
    <Header
      :title="$t('cmp.signin.header')"
      :isCanGoPrev="isCanGoPrev"
      :onGoPrev="goPrev"
    />

    <SignIn
      :onSignIn="onSignIn"
    />
  </div>
</template>

<script>
import Header from '@/ui/components/Header'
import SignIn from '@/ui/components/profile/SignIn'

export default {
  name: 'SignInPage',
  components: {
    Header,
    SignIn,
  },

  data() {
    return {
      isCanGoPrev: window.history.length > 2
    }
  },

  methods: {
    goPrev() {
      this.$router.back();
    },

    onSignIn() {
      this.$router.replace('/profile')
    }
  }
}
</script>

<style lang="scss">
</style>