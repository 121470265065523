import Api from '@/utils/Api';
import VendorSettingsModel from '@/models/VendorSettingsModel';

const vendorSettingsApi = {
  async loadSettings(publicAPIKey) {
    const response = await Api.get(`/vendor-settings/${publicAPIKey}`);
    return new VendorSettingsModel().pushPayload(response.data.vendor_settings);
  }
}

export default vendorSettingsApi;