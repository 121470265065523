<template>
  <div class="summary-step">
    <ReservationDetails
      :reservation="reservation"
    />

    <BottomBar>
      <div class="row">
        <div class="col d-flex mb-3">
          <div class="yacaptcha" ref="yacaptcha"></div>
        </div>
        <div class="col-md-5 d-flex align-items-center mb-3">
          <Button
            :text="isOnlinePaymentRequired ? $t('buttons.createAndPay') : $t('buttons.create')"
            :action="() => submitReservation()"
            :isDisabled="!yaCaptchaToken || isSubmiting"
          />
        </div>
      </div>
      <div class="privacy-note" v-html="$t('cmp.summaryStep.privacyPolicy')"></div>
    </BottomBar>
  </div>
</template>

<script>
import BottomBar from '@/ui/components/BottomBar'
import Button from '@/ui/components/Button'
import ReservationDetails from '@/ui/components/ReservationDetails'
import reservationService from '@/services/ReservationService'
import settingsService from '@/services/SettingsService'

const YACAPTCHA_KEY = process.env.VUE_APP_YACAPTCHA_KEY;

export default {
  name: 'SummaryStep',
  props: {
    reservation: Object,
    onReservationSubmit: Function,
  },
  components: {
    BottomBar,
    Button,
    ReservationDetails,
  },

  data() {
    return {
      yaCaptchaToken: null,
      isSubmiting: false,
      isOnlinePaymentRequired: false,
    }
  },

  async mounted() {
    this.renderYaCaptcha();
    await this.loadSettings();
  },

  methods: {
    async loadSettings() {
      await settingsService.loadPointSettings(this.reservation.point.id);
      this.isOnlinePaymentRequired = settingsService.getIsOnlinePaymentRequired();
    },

    loadScript(src) {
      return new Promise(function (resolve, reject) {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },

    async renderYaCaptcha() {
      await this.loadScript('https://captcha-api.yandex.ru/captcha.js?render=onload');

      window.smartCaptcha.render(this.$refs.yacaptcha, {
        sitekey: YACAPTCHA_KEY,
        hl: settingsService.getLocale(),
        callback: (token) => {
          this.yaCaptchaToken = token;
        }
      });
    },

    async submitReservation() {
      this.isSubmiting = true;

      try {
        const reservation = await reservationService.saveReservation(this.reservation, this.yaCaptchaToken);
        this.onReservationSubmit(reservation, this.isOnlinePaymentRequired);
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error'
        });
      } finally {
        this.isSubmiting = false;
      }
    }
  }
}
</script>

<style lang="scss">
.summary-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  .privacy-note {
    font-size: 12px;
    color: $grayText;
  }
}
</style>