class VendorStore {
  publicAPIKey = null;

  setPublicAPIKey(publicAPIKey) {
    this.publicAPIKey = publicAPIKey;
  }

  getPublicAPIKey() {
    return this.publicAPIKey;
  }
}

const vendorStore = new VendorStore();

export default vendorStore;
