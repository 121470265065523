<template>
  <div class="resources-step">
    <div class="resources-list">
      <div
        class="item"
        v-for="resource in resources"
        :key="resource.id"
        @click="() => onResourceClick(resource)"
      >
        <div class="left-col">
          <img
            v-if="resource.photo?.thumb_src"
            class="avatar"
            :src="resource.photo?.thumb_src"
          />
          <img
            v-else
            class="avatar"
            src="@/ui/assets/images/default_avatar.png"
          />

          <div class="info">
            <div class="name">
              {{resource.id == 'any' ? $t('cmp.resourcesStep.anyResource') : resource.name}}
            </div>
            <div class="specialization">
              {{resource.specialization}}
            </div>

            <div
              class="nearest-time"
              v-if="resource.nearest_time"
            >
              <div class="date">
                {{ $t('cmp.resourcesStep.avlbTime') }} <b>{{moment(resource.nearest_time.date).format("D MMM")}}</b>
              </div>
              <div class="time">
                <div
                  v-for="time in resource.nearest_time?.time.slice(0, 6)"
                  :key="resource.id + time"
                  class="time-pill"
                  @click="(event) => {
                    event.stopPropagation();
                    onTimeClick(resource, resource.nearest_time.date, time)
                  }"
                >
                  {{intToTime24(time)}}
                </div>
              </div>
            </div>
            <div
              class="no-nearest-time"
              v-else
            >
              {{ $t('cmp.resourcesStep.noAvlbTime') }}
            </div>

          </div>
        </div>
        <div class="right-col">
          <img src="@/ui/assets/icons/chevron-right.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { intToTime24 } from '@/utils/Helpers'
import resourceService from '@/services/ResourceService';

export default {
  name: 'ResourcesStep',
  props: {
    reservation: Object,
    onSelectResource: Function,
    onSelectResourceAndDateTime: Function,
  },

  data() {
    return {
      moment,
      intToTime24,
      resources: null,
    }
  },

  mounted() {
    this.loadResources();
  },

  methods: {
    async loadResources() {
      try {
        this.resources = await resourceService.getResources(
          this.reservation.point.id,
          this.reservation.reservation_services.map(rs => rs.service.id)
        );
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error'
        });
      }
    },

    onResourceClick(resource) {
      this.onSelectResource(resource);
    },

    onTimeClick(resource, date, time) {
      const time24 = intToTime24(time).split(':');
      const dateTime = moment(date).set(
        {hour: parseInt(time24[0]), minute: parseInt(time24[1]), second:0}
      ).toDate();
      this.onSelectResourceAndDateTime(resource, dateTime);
    }
  }
}
</script>

<style lang="scss">
.resources-step {
  .resources-list {
    & > .item {
      cursor: pointer;
      display: flex;
      background-color: white;
      border-bottom: 1px solid $border;
      padding: 15px;

      .left-col {
        flex-grow: 1;
        display: flex;

        .avatar {
          width: 100px;
          height: 100px;
          border-radius: 10px;
          margin-right: 15px;
        }

        .info {
          .specialization {
            font-size: 14px;
          }

          .nearest-time {
            margin-top: 5px;
            .date {
              font-size: 14px;
              margin-bottom: 5px;
              color: $grayText;
            }

            .time {
              display: flex;
              flex-wrap: wrap;

              .time-pill {
                background-color: $primary;
                color: white;
                border-radius: 5px;
                font-size: 14px;
                padding: 3px 8px;
                margin: 0 10px 5px 0;
                width: 50px;
                text-align: center;
                cursor: pointer;
              }
            }
          }

          .no-nearest-time {
            color: $redText;
            font-size: 14px;
          }
        }
      }

      .right-col {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        padding: 0 5px;
      }
    }
  }
}
</style>