<template>
  <div class="services-list">
    <div
      v-for="s in services"
      :key="s.id"
      class="item"
      :class="{ 'selectable': isSelectable}"
      @click="() => {if (this.isSelectable) onSelect(s);}"
    >
      <div
        class="checkbox"
        v-if="isSelectable"
        :class="{ checked: selectedServicesIds.includes(s.id) }"
      >
        <img
          src="@/ui/assets/icons/check-small.svg"
          v-if="selectedServicesIds.includes(s.id)"
        />
      </div>

      <div class="info">
        <div class="name">
          {{s.name}}
        </div>
        <div
          class="description"
          v-if="s.description"
        >
          {{s.description}}
        </div>
        <div class="props">
          <div class="prop duration">
            <img src="@/ui/assets/icons/timer.svg" />
            {{intToDuration(s.duration, $i18n.locale)}}
          </div>
          <div class="prop price">
            <img src="@/ui/assets/icons/wallet.svg" />
            <span
              v-if="s.min_price && s.max_price"
            >
              {{s.min_price}} - {{s.max_price}} {{currencySign}}
            </span>
            <span
              v-else-if="s.min_price"
            >
              {{ $t('cmp.servicesList.from') }} {{s.min_price}} {{currencySign}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { intToDuration } from '@/utils/Helpers'

export default {
  name: "ServicesList",
  props: {
    isSelectable: Boolean,
    services: Array,
    selectedServices: {
      type: Array,
      default() {
        return [];
      }
    },
    currencySign: String,
    onSelect: Function,
  },

  data() {
    return {
      intToDuration
    }
  },

  computed: {
    selectedServicesIds: function () {
      return this.selectedServices.map(service => service.id);
    }
  }
};
</script>

<style lang="scss">
.services-list {
  & > .item {
    border-bottom: 1px solid $border;
    padding: 15px 20px;
    display: flex;
    align-items: center;

    &.selectable {
      cursor: pointer;
    }

    .checkbox {
      border: 1px solid $primary;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      flex: 0 0 auto;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      &.checked {
        background-color: $primary;
      }
    }

    .info {
      .name {
        margin-bottom: 3px;
      }

      .description {
        font-size: 14px;
        margin-bottom: 3px;
      }

      .props {
        font-size: 12px;
        display: flex;

        .prop {
          margin-right: 10px;
          display: flex;
          align-items: center;

          img {
            height: 12px;
            margin-right: 3px;
          }
        }

      }
    }
  }
}
</style>
