<template>
  <div class="datetime-step">
    <InlineCalendar
      :availableDates="availableDates"
      :selectedDate="selectedDate"
      :onDateSelect="onDateSelect"
    />

    <div class="available-time">
      <div
        class="time"
        v-if="daySchedule && daySchedule.length"
      >
        <div
          v-for="time in daySchedule"
          :key="time"
          class="time-pill"
          @click="(event) => {
            event.stopPropagation();
            onTimeClick(time)
          }"
        >
          {{intToTime24(time)}}
        </div>
      </div>

      <div
        class="no-available-time"
        v-else
      >
        {{ $t('cmp.dateTimeStep.noAvlbTimeForDate') }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { intToTime24 } from '@/utils/Helpers'
import InlineCalendar from '@/ui/components/InlineCalendar'
import resourceScheduleService from '@/services/ResourceScheduleService';

export default {
  name: 'DateTimeStep',
  props: {
    reservation: Object,
    onSelectDateTime: Function,
  },
  components: {
    InlineCalendar,
  },

  data() {
    return {
      intToTime24,
      resourceSchedule: {},
      selectedDate: moment(this.reservation.start_date || new Date()).format('YYYY-MM-DD'),
    }
  },

  async mounted() {
    await this.loadResourceSchedule();
    this.selectFirstAvailabelDate();
  },

  computed: {
    availableDates() {
      const dates = Object.keys(this.resourceSchedule);

      let availableDates = [];
      dates.forEach(dateKey => {
        if (this.resourceSchedule[dateKey].length) {
          availableDates.push(dateKey);
        }
      });

      return availableDates;
    },

    daySchedule() {
      return this.resourceSchedule[this.selectedDate];
    }
  },

  methods: {
    async loadResourceSchedule() {
      try {
        this.resourceSchedule = await resourceScheduleService.getResourceSchedule(
          this.reservation.point.id,
          this.reservation?.resource?.id,
          this.reservation.reservation_services.map(rs => rs.service.id)
        );
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error'
        });
      }
    },

    selectFirstAvailabelDate() {
      if (this.availableDates.length && !this.reservation.start_date) {
        this.onDateSelect(this.availableDates[0]);
      }
    },

    onDateSelect(date) {
      this.selectedDate = date;
    },

    onTimeClick(time) {
      const time24 = intToTime24(time).split(':');
      const dateTime = moment(this.selectedDate).set(
        {hour: parseInt(time24[0]), minute: parseInt(time24[1]), second:0}
      ).toDate();
      this.onSelectDateTime(dateTime);
    }
  }
}
</script>

<style lang="scss">
.datetime-step {
  .available-time {
    padding: 20px 15px;

    .time {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .time-pill {
        background-color: $primary;
        color: white;
        border-radius: 5px;
        font-size: 14px;
        padding: 8px 8px;
        margin: 10px;
        width: 19%;
        text-align: center;
        cursor: pointer;
      }
    }

    .no-available-time {
      color: $grayText;
      text-align: center;
      padding: 20px 0;
    }
  }
}
</style>