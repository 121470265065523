import Api from '@/utils/Api';

const authApi = {
  async sendCode(email, captchaToken) {
    const response = await Api.post(`/auth/send-code`, {
      data: {
        email,
        captcha_token: captchaToken
      }
    });
    return response.status == 200;
  },

  async signIn(email, code) {
    const response = await Api.post(`/auth/sign-in`, {
      data: {
        email,
        code
      }
    });
    return response.data.jwt;
  },

  async signUp(firstName, lastName, phone, email, captchaToken) {
    const response = await Api.post(`/auth/sign-up`, {
      data: {
        first_name: firstName,
        last_name: lastName,
        phone,
        email,
        captcha_token: captchaToken
      }
    });
    return response.status == 200;
  }
}

export default authApi;