<template>
  <button
    :class="cls"
    :disabled="isDisabled"
    @click="onClick"
  >
    {{text}}
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    action: Function,
    text: String,
    variant: {
      type: String,
      default: 'solid' //solid | outline
    },
    isDisabled: Boolean,
  },

  data() {
    return {
      cls: `button ${this.variant}`
    }
  },

  methods: {
    onClick(event) {
      event.preventDefault();
      this.action();
    }
  },
};
</script>

<style lang="scss">
.button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  height: 52px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    text-decoration: none;
  }
  
  &.solid {
    color: white;
    background-color: $primary;
  }
    
  &.outline {
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: .75;
  }
}
</style>
