import App from './ui/App.vue'
import router from './ui/router.js'
import i18n from "./ui/i18n"
import { createApp } from 'vue'

import bridge from '@vkontakte/vk-bridge'
import Maska from 'maska'
import YmapPlugin from 'vue-yandex-maps'


import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

const app = createApp(App);

app.use(router)

app.use(VueToast);

app.use(YmapPlugin, {
  apiKey: 'cb6d57f6-7120-4ea5-b02a-d98b9063432a',
});

app.use(Maska);

app.use(i18n);

app.mount('#app')

// Инициализация Mini App VK
bridge.send('VKWebAppInit');
