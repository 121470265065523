<template>
  <div class="signup">

    <div class="logo">
      <img src="@/ui/assets/images/logo_big.svg" />
    </div>

    <!-- Если код еще не отправлен -->
    <div
      class="form-wrapper"
      v-if="!isCodeSent"
    >
      <div class="form-group">
        <label class="form-label required">{{ $t('cmp.signup.firstName') }}</label>
        <input
          class="form-control"
          v-model="userFirstName"
        />
        <div
          class="form-invalid-text"
          v-if="errors['userFirstName']"
        >
          {{errors['userFirstName']}}
        </div>
      </div>

      <div class="form-group">
        <label class="form-label required">{{ $t('cmp.signup.lastName') }}</label>
        <input
          class="form-control"
          v-model="userLastName"
        />
        <div
          class="form-invalid-text"
          v-if="errors['userLastName']"
        >
          {{errors['userLastName']}}
        </div>
      </div>

      <div class="form-group">
        <label class="form-label required">{{ $t('cmp.signup.phone') }}</label>
        <input
          class="form-control"
          type="tel"
          v-model="userPhone"
          v-maska="'+# (###) ###-##-####'"
        />
        <div
          class="form-invalid-text"
          v-if="errors['userPhone']"
        >
          {{errors['userPhone']}}
        </div>
      </div>

      <div class="form-group">
        <label class="form-label required">Email</label>
        <input
          class="form-control"
          v-model="userEmail"
        />
        <div
          class="form-invalid-text"
          v-if="errors['userEmail']"
        >
          {{errors['userEmail']}}
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="yacaptcha" ref="yacaptcha"></div>
        </div>
        <div class="col-md-4 ">
          <Button
            :text="$t('cmp.signup.send')"
            :isDisabled="!yaCaptchaToken || isSubmiting"
            :action="() => signUp()"
          />
        </div>
      </div>
    </div>

    <!-- Код отправлен -->
    <div
      class="form-wrapper"
      v-if="isCodeSent"
    >
      <div class="form-group">
        <label class="form-label required">{{ $t('cmp.signup.emailCode') }}</label>
        <input
          class="form-control"
          v-model="userCode"
        />
        <div
          class="form-invalid-text"
          v-if="errors['userCode']"
        >
          {{errors['userCode']}}
        </div>
      </div>

      <div class="row justify-content-end">
        <div class="col-md-4">
          <Button
            :text="$t('cmp.signup.signIn')"
            :action="() => signIn()"
          />
        </div>
      </div>
    </div>

    <div class="bottom-links">
      {{ $t('cmp.signup.alreadyHaveAccount') }} <br>
      <router-link to="/profile/signin" replace>{{ $t('cmp.signup.signInLink') }}</router-link>
    </div>
  </div>
</template>

<script>
import Button from '@/ui/components/Button'
import settingsService from '@/services/SettingsService'
import authService from '@/services/AuthService'
import { PHONE_REGEX, EMAIL_REGEX } from '@/utils/Constants'

const YACAPTCHA_KEY = process.env.VUE_APP_YACAPTCHA_KEY;

export default {
  name: 'SignUp',
  props: {
    onSignIn: Function,
  },
  components: {
    Button,
  },

  data() {
    return {
      userFirstName: '',
      userLastName: '',
      userPhone: '+',
      userEmail: '',
      userCode: null,
      yaCaptchaToken: null,
      isSubmiting: false,
      isCodeSent: false,
      errors: {}
    }
  },

  mounted() {
    this.renderYaCaptcha();
  },

  methods: {
    loadScript(src) {
      return new Promise(function (resolve, reject) {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },

    async renderYaCaptcha() {
      await this.loadScript('https://captcha-api.yandex.ru/captcha.js?render=onload');

      window.smartCaptcha.render(this.$refs.yacaptcha, {
        sitekey: YACAPTCHA_KEY,
        hl: settingsService.getLocale(),
        callback: (token) => {
          this.yaCaptchaToken = token;
        }
      });
    },

    async signUp() {
      this.errors = {};

      // Провека имени
      if (!this.userFirstName.length) {
        this.errors['userFirstName'] = 'Поле обязательное';
      }
      if (!this.userLastName.length) {
        this.errors['userLastName'] = 'Поле обязательное';
      }

      // Провека телефона
      if (!this.userPhone.length) {
        this.errors['userPhone'] = 'Поле обязательное';
      } else if (!this.userPhone.match(PHONE_REGEX)) {
        this.errors['userPhone'] = 'Введите правильный номер телефона';
      }

      // Проверка почты
      if (!this.userEmail) {
        this.errors['userEmail'] = 'Поле обязательное';
      } else if (this.userEmail.length && !this.userEmail.match(EMAIL_REGEX)) {
        this.errors['userEmail'] = 'Введите правильный адрес почты';
      }

      if (Object.keys(this.errors).length > 0) {
        return;
      }

      this.isSubmiting = true;

      try {
        this.isCodeSent = await authService.signUp(
          this.userFirstName, this.userLastName, this.userPhone, this.userEmail,
          this.yaCaptchaToken
        );
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error'
        });
      } finally {
        this.isSubmiting = false;
      }
    },

    async signIn() {
      this.errors = {};

      // Провека имени
      if (!this.userCode.length) {
        this.errors['userCode'] = 'Поле обязательное';
      }

      if (Object.keys(this.errors).length > 0) {
        return;
      }

      this.isSubmiting = true;

      try {
        let isSignIn = await authService.signIn(this.userEmail, this.userCode);
        if (isSignIn) {
          this.onSignIn();
        }
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error'
        });
      } finally {
        this.isSubmiting = false;
      }
    }
  }
}
</script>

<style lang="scss">
.signup {

  .logo {
    margin: 40px 0 0 0;
    text-align: center;
  }

  .title {
    text-align: center;
    padding: 0 20px;
  }

  .form-wrapper {
    padding: 30px 40px;
    margin-bottom: 20px;

    .form-invalid-text {
      font-size: 14px;
      color: $redText;
      margin-top: 3px;
    }
  }

  .bottom-links {
    font-size: 14px;
    text-align: center;
    margin-bottom: 40px;
  }
}
</style>