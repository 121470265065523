<template>
  <div class="profile-page">
    <Header
      :title="$t('cmp.profile.header')"
      :isCanGoPrev="isCanGoPrev"
      :onGoPrev="goPrev"
    />

    <Profile
      v-if="isAuthenticated"
      :onSignOut="onSignOut"
      :goToReservation="goToReservation"
    />
  </div>
</template>

<script>
import Header from '@/ui/components/Header'
import Profile from '@/ui/components/profile/Profile'
import authService from '@/services/AuthService'


export default {
  name: 'ProfilePage',
  components: {
    Header,
    Profile,
  },

  data() {
    return {
      isAuthenticated: authService.isAuthenticated(),
      isCanGoPrev: window.history.length > 2
    }
  },

  mounted() {
    if (!authService.isAuthenticated()) {
      this.$router.replace('/profile/signin')
    }
  },

  methods: {
    goToReservation(reservation) {
      this.$router.push(`/reservations/${reservation.code}`);
    },

    goPrev() {
      this.$router.back();
    },

    onSignOut() {
      this.$router.go();
    },
  }
}
</script>

<style lang="scss">
</style>