import Api from '@/utils/Api';
import ResourceModel from '@/models/ResourceModel';

const resourceApi = {
  async getResources(pointId, servicesIds) {
    const response = await Api.get(`/resources?point_id=${pointId}&services_ids=${servicesIds}`);
    return response.data.resources.map((resourcePayload) => {
      return new ResourceModel().pushPayload(resourcePayload);
    });
  }
}

export default resourceApi;