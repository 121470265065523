<template>
  <div class="service-categories">
    <div
      v-for="sc in serviceCategories"
      :key="sc.id"
      class="item"
    >
      <div
        class="header"
        @click="onHeaderClick(sc)"
      >
        <div class="title">
          {{sc.name}}
        </div>
        <img
          v-if="this.openCategoryId == sc.id"
          src="@/ui/assets/icons/chevron-up.svg"
        />
        <img
          v-else
          src="@/ui/assets/icons/chevron-down.svg"
        />
      </div>
      <div
        class="body"
        v-if="this.openCategoryId == sc.id"
      >
        <ServicesList
          :isSelectable="true"
          :services="sc.services"
          :selectedServices="selectedServices"
          :currencySign="currencySign"
          :onSelect="onSelect"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ServicesList from '@/ui/components/ServicesList'

export default {
  name: "ServiceCategoriesAccordion",
  props: {
    serviceCategories: Array,
    selectedServices: Array,
    currencySign: String,
    onSelect: Function,
  },
  components: {
    ServicesList,
  },

  data() {
    return {
      openCategoryId: null
    }
  },

  methods: {
    onHeaderClick(sc) {
      if (this.openCategoryId == sc.id) {
        this.openCategoryId = null;
      } else {
        this.openCategoryId = sc.id;
      }
    }
  },
};
</script>

<style lang="scss">
.service-categories {
  & > .item {
    .header {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      border-bottom: 1px solid $border;
      background-color: white;
      cursor: pointer;
    }

    .body {
      border-bottom: 1px solid $border;
    }
  }
}
</style>
