const settingsStorage = {
  settings: {},

  setParam(key, value) {
    this.settings[key] = value;
  },

  getParam(key) {
    return this.settings[key];
  }
};

export default settingsStorage;
