import settingsStorage from "@/stores/SettingsStorage";
import vendorSettingsApi from "@/apis/VendorSettingsApi";
import pointSettingsApi from "@/apis/PointSettingsApi";
import { DEFAULT_LOCALE } from '@/utils/Constants';

const settingsService = {
  async loadVendorSettings(publicAPIKey) {
    const vendorSettings = await vendorSettingsApi.loadSettings(publicAPIKey);

    settingsStorage.setParam('locale', vendorSettings.booking_locale);
    settingsStorage.setParam('isEmailRequired', vendorSettings.is_booking_email_required);
    settingsStorage.setParam('isSkipResource', vendorSettings.is_booking_skip_resource);
    settingsStorage.setParam('isDisableCanceling', vendorSettings.is_booking_disable_canceling);
    settingsStorage.setParam('yaMetrikaId', vendorSettings.ya_metrika_id);
  },

  async loadPointSettings(pointId) {
    const pointSettings = await pointSettingsApi.loadSettings(pointId);
    settingsStorage.setParam('isOnlinePaymentRequired', pointSettings.is_online_payment_required);
  },

  setLocale(locale) {
    settingsStorage.setParam('locale', locale);
  },

  getLocale() {
    return settingsStorage.getParam('locale') || DEFAULT_LOCALE;
  },

  getIsEmailRequired() {
    return settingsStorage.getParam('isEmailRequired');
  },

  getIsSkipResource() {
    return settingsStorage.getParam('isSkipResource');
  },

  getIsDisableCanceling() {
    return settingsStorage.getParam('isDisableCanceling');
  },

  getYaMetrikaId() {
    return settingsStorage.getParam('yaMetrikaId');
  },

  getIsOnlinePaymentRequired() {
    return settingsStorage.getParam('isOnlinePaymentRequired');
  }
}

export default settingsService;