<template>
  <div class="services-step">

    <div>
      <Promotions
        :point="reservation.point"
      />

      <ServiceCategoriesAccordion
        :serviceCategories="serviceCategories"
        :selectedServices="selectedServices"
        :currencySign="currencySign"
        :onSelect="onServiceSelect"
      />
    </div>

    <BottomBar
      v-if="selectedServices.length"
    >
      <div class="row">
        <div class="col d-flex mb-2">
          <Cart
            :selectedServices="selectedServices"
            :currencySign="currencySign"
            :onClear="onClearCart"
          />
        </div>

        <div
          class="col-auto col-md-4 mb-2 d-flex align-items-center"
          v-if="isResourcesAvailable != false"
        >
          <Button
            :text="$t('buttons.next')"
            :isDisabled="!isResourcesAvailable"
            :action="() => onSelectServices(selectedServices)"
          />
        </div>

        <div
          class="col-md-7 resources-not-available"
          v-if="isResourcesAvailable == false"
        >
          {{ $t('cmp.servicesStep.resourcesNotAvailable') }}
        </div>
      </div>
    </BottomBar>
  </div>
</template>

<script>
import BottomBar from '@/ui/components/BottomBar'
import Button from '@/ui/components/Button'
import Cart from '@/ui/components/Cart'
import Promotions from '@/ui/components/Promotions'
import ServiceCategoriesAccordion from '@/ui/components/ServiceCategoriesAccordion'
import resourceService from '@/services/ResourceService'
import serviceService from '@/services/ServiceService'

export default {
  name: 'ServicesStep',
  props: {
    reservation: Object,
    onSelectServices: Function,
  },
  components: {
    BottomBar,
    Button,
    Cart,
    Promotions,
    ServiceCategoriesAccordion,
  },

  data() {
    return {
      serviceCategories: null,
      selectedServices: this.reservation.reservation_services.map(rs => rs.service),
      currencySign: this.reservation.point.country.currency.sign,
      isResourcesAvailable: null // Есть ли мастера способные выполнить эти записи
    }
  },

  mounted() {
    this.loadCategoriesWithServices();

    if (this.selectedServices.length > 0) {
      this.checkResources();
    }
  },

  methods: {
    async loadCategoriesWithServices() {
      try {
        this.serviceCategories = await serviceService.getCategoriesWithServices(this.reservation.point.id);
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error'
        });
      }
    },

    async checkResources() {
      /* Проверка что выбранные услуги может выполнить хотя бы один мастер
      */

      this.isResourcesAvailable = null;
      try {
        let resources = await resourceService.getResources(
          this.reservation.point.id,
          this.selectedServices.map(s => s.id)
        );

        this.isResourcesAvailable = resources.length > 0
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error'
        });
      }
    },

    onServiceSelect(service) {
      const serviceIndex = this.selectedServices.findIndex(s => s.id == service.id);

      // Если элемент есть в массиве - удаляем, если нет - добавляем;
      if (serviceIndex >= 0) {
        this.selectedServices.splice(serviceIndex, 1);
      } else {
        this.selectedServices.push(service);
      }

      this.checkResources();
    },

    onClearCart() {
      this.selectedServices = [];
    }
  }
}
</script>

<style lang="scss">
.services-step {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .resources-not-available {
    font-size: 12px;
    color: $redText;
  }
}
</style>