import { Model, attr, belongsTo, hasMany } from '@elatonsev/js-models';
import PointModel from './PointModel';
import ResourceModel from './ResourceModel';
import ReservationServiceModel from './ReservationServiceModel';
import ClientModel from './ClientModel';

export default class ReservationModel extends Model {
  start_date = attr('date');
  client_note = attr('string');
  code = attr('string');
  total_cost = attr('number');
  amount_online = attr('number');
  point = belongsTo(PointModel);
  resource = belongsTo(ResourceModel);
  reservation_services = hasMany(ReservationServiceModel);
  client = belongsTo(ClientModel);
}