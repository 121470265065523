const LOCAL_STORAGE_KEY = 'pg_booking_user_session';

const userSessionStorage = {
  saveSession(options) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(options));
  },

  getSession() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
  }
};

export default userSessionStorage;
