
// Округляет число с плавающей точкой до двух знаков после запятой
function formatMoney(value) {
  value = parseFloat(value);

  if (!isNaN(value)) {
    return (parseInt(value * 100) / 100).toFixed(2);
  } else {
    return value;
  }
}

// Запись строки с большой буквы
function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Время в минутах конвертирует во запись в 24 часовой формат
function intToTime24(timeInMins) {
  let hours = parseInt(timeInMins / 60);
  let mins = parseInt(timeInMins % 60);

  if (mins < 10) {
    mins = `0${mins}`;
  }

  return `${hours}:${mins}`
}

// Время в минутах конвертирует в продолжительность
function intToDuration(timeInMins, location='ru') {
  const TRANSLATIONS = {
    en: {
      min: 'min.',
      hour: 'h.'
    },
    ru: {
      min: 'мин.',
      hour: 'ч.'
    },
    tr: {
      min: 'dk.',
      hour: 'saat'
    },
    sr: {
      min: 'мин.',
      hour: 'ч.'
    },
  }

  let hours = parseInt(timeInMins / 60);
  let mins = parseInt(timeInMins % 60);

  if (hours == 0) {
    return `${mins} ${TRANSLATIONS[location].min}`
  }

  return `${hours} ${TRANSLATIONS[location].hour} ${mins} ${TRANSLATIONS[location].min}`
}

// Склонения
function getNoun(number, one, two, five) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}

export {
  capitalize,
  formatMoney,
  getNoun,
  intToDuration,
  intToTime24,
};