<template>
  <div class="page-header">
    <div class="left-controls">
      <div
        class="header-btn"
        v-if="isCanGoPrev"
        @click="goPrev"
      >
        <img src="@/ui/assets/icons/chevron-left.svg" />
      </div>
    </div>
    <div class="title">{{title}}</div>
    <div class="right-controls">
      <LocaleSelector/>

      <router-link
        to="/profile"
        class="profile-link"
      >
        <div
          class="header-btn"
        >
          <img src="@/ui/assets/icons/profile.svg" />
        </div>
        <!-- <Popover
          v-if="isShowAuthPopover"
        >
          {{ $t('cmp.header.authHint') }}
        </Popover> -->
      </router-link>
    </div>
  </div>
</template>

<script>
import LocaleSelector from '@/ui/components/LocaleSelector'
// import Popover from '@/ui/components/Popover'

export default {
  name: 'Header',
  components: {
    LocaleSelector,
    // Popover,
  },
  props: {
    title: String,
    isCanGoPrev: Boolean,
    isShowAuthPopover: {
      type: Boolean,
      default: false
    },
    onGoPrev: Function,
  },
  methods: {
    goPrev() {
      this.onGoPrev()
    }
  }
}
</script>

<style lang="scss">
.page-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: white;
  background-color: $primary;
  border-radius: $pageBorderRadious $pageBorderRadious 0 0;

  .left-controls,
  .right-controls {
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .right-controls {
    width: 90px;
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 20px;
  }

  .header-btn {
    background-color: $primaryLight;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      margin-left: -1px;
    }
  }

  .profile-link {
    position: relative;
  }
}
</style>