<template>
  <div class="bottom-bar">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BottomBar',
}
</script>

<style lang="scss">
.bottom-bar {
  position: -webkit-sticky; //for mobile browser
  position: sticky;
  bottom: 0;

  padding: 20px;
  background-color: white;
  border-radius: 0 0 $pageBorderRadious $pageBorderRadious;
}
</style>