<template>
  <div class="canceling-step">
    <ReservationDetails
      :reservation="reservation"
    />

    <BottomBar>
      <!-- Панель с кнопками -->
      <div
        class="row"
        v-if="!mode"
      >
        <div class="col">
          <!-- Отмена записи запрещена -->
          <div
            v-if="isDisableCanceling === true"
          >
            Отмена записи по телефону: <a :href="`tel:${this.reservation.point.contact_phone}`">{{this.reservation.point.contact_phone}}</a>
          </div>

          <!-- Отмена записи разрешена -->
          <Button
            v-else
            :variant="'outline'"
            :text="$t('buttons.cancel')"
            :action="() => whantToCancel()"
          />
        </div>

        <div class="col">
          <!-- Подтверждение записи -->
          <Button
            v-if="isCanConfirm"
            :text="$t('buttons.confirm')"
            :action="() => whantToConfirm()"
          />

          <!-- Завершение бронирования и переход на главный экран -->
          <Button
            v-else
            :text="$t('buttons.finish')"
            :action="() => finish()"
          />
        </div>
      </div>

      <!-- Панель подтверждения записи вводом номера телефона -->
      <div
        class="row"
        v-else
      >
        <div class="col-md-8 mb-3">
          <label class="form-label required">{{ $t('cmp.detailsStep.phone') }}</label>
          <input
            class="form-control"
            type="tel"
            v-model="clientPhone"
            v-maska="'+# (###) ###-##-####'"
          />
        </div>
        <div class="col-md-4 d-flex align-items-end mb-3">
          <Button
            :text="$t('buttons.confirm')"
            :action="() => submit()"
            :isDisabled="!isPhoneValid || isSubmiting"
          />
        </div>
      </div>
    </BottomBar>
  </div>
</template>

<script>
import i18n from '@/ui/i18n'
import BottomBar from '@/ui/components/BottomBar'
import Button from '@/ui/components/Button'
import ReservationDetails from '@/ui/components/ReservationDetails'
import reservationService from '@/services/ReservationService'
import { PHONE_REGEX } from '@/utils/Constants'

const [CONFIRM, CANCEL] = ['confirm', 'cancel'];

export default {
  name: 'DetailsStep',
  props: {
    reservation: Object,
    isDisableCanceling: Boolean,
    onCancel: Function,
    onConfirm: Function,
    onFinish: Function,
  },

  components: {
    BottomBar,
    Button,
    ReservationDetails,
  },

  data() {
    return {
      clientPhone: '+',
      isSubmiting: false,
      mode: null,
      isCanConfirm: !!this.$route.query?.isConfirm
    };
  },

  computed: {
    isPhoneValid() {
      return this.clientPhone.match(PHONE_REGEX);
    }
  },

  methods: {
    whantToCancel() {
      this.mode = CANCEL;
    },
      
    whantToConfirm() {
      this.mode = CONFIRM;
    },

    finish() {
      this.onFinish();
    },

    submit() {
      if (this.mode == CONFIRM) {
        this.confirmReservation();
      } else {
        this.cancelReservation();
      }
    },

    async cancelReservation() {
      this.isSubmiting = true;

      try {
        await reservationService.cancelReservation(this.reservation.code, this.clientPhone);
        this.$toast.open({
          message: i18n.global.t('cmp.detailsStep.successfullyCanceled'),
          type: 'success'
        });

        this.onCancel();
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error'
        });
      } finally {
        this.isSubmiting = false;
      }
    },

    async confirmReservation() {
      this.isSubmiting = true;

      try {
        await reservationService.confirmReservation(this.reservation.code, this.clientPhone);
        this.$toast.open({
          message: i18n.global.t('cmp.detailsStep.successfullyConfirmed'),
          type: 'success'
        });

        this.onConfirm();
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error'
        });
      } finally {
        this.isSubmiting = false;
      }
    }
  }
}
</script>

<style lang="scss">
.canceling-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
</style>