import { Model, attr, belongsTo } from '@elatonsev/js-models';
import TubeStationModel from './TubeStationModel';
import VendorModel from './VendorModel';
import CityModel from './CityModel';
import CountryModel from './CountryModel';

export default class PointModel extends Model {
  name = attr('string');
  address = attr('string');
  address_overview = attr('string');
  contact_phone = attr('string');
  latitude = attr('number');
  longitude = attr('number');
  tube_station = belongsTo(TubeStationModel);
  vendor = belongsTo(VendorModel);
  city = belongsTo(CityModel);
  country = belongsTo(CountryModel);
}