import Api from '@/utils/Api';

const resourceScheduleApi = {
  async getResourceSchedule(pointId, resourceId, servicesIds) {
    let params = `point_id=${pointId}&services_ids=${servicesIds}`;

    if (resourceId) {
      params += `&resource_id=${resourceId}`;
    }

    const response = await Api.get(`resource-schedule?${params}`);
    return response.data['resource-schedule'];
  }
}

export default resourceScheduleApi;