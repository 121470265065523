<template>
  <div class="canceling-page">
    <Header
      :title="'Детали записи'"
      :isCanGoPrev="true"
      :onGoPrev="goPrev"
    />

    <CompanyCard
      v-if="reservation"
      :point="reservation.point"
    />

    <DetailsStep
      v-if="reservation"
      :reservation="reservation"
      :isDisableCanceling="isDisableCanceling"
      :onCancel="onSuccess"
      :onConfirm="onSuccess"
      :onFinish="onSuccess"
    />
  </div>
</template>

<script>
import DetailsStep from '@/ui/components/steps/9.DetailsStep'
import CompanyCard from '@/ui/components/CompanyCard'
import Header from '@/ui/components/Header'
import reservationService from '@/services/ReservationService'
import settingsService from '@/services/SettingsService'
import vendorStore from '@/stores/VendorStore'

export default {
  name: 'ReservationPage',
  components: {
    DetailsStep,
    CompanyCard,
    Header,
  },

  data() {
    return {
      reservationCode: null,
      reservation: null,
      isDisableCanceling: null
    }
  },

  async mounted() {
    this.parseUrlParams();
    await this.getReservation();
    await this.loadSettings();
  },

  methods: {
    parseUrlParams() {
      this.reservationCode = this.$route.params.reservationCode;
    },

    async getReservation() {
      this.reservation = await reservationService.getReservationByCode(this.reservationCode);
    },

    async loadSettings() {
      vendorStore.setPublicAPIKey(this.reservation.point.vendor.public_api_key);

      await settingsService.loadVendorSettings(this.reservation.point.vendor.public_api_key);
      await settingsService.loadPointSettings(this.reservation.point.id);
      const isOnlinePaymentRequired = settingsService.getIsOnlinePaymentRequired();

      this.isDisableCanceling = isOnlinePaymentRequired || settingsService.getIsDisableCanceling();
    },

    goPrev() {
      const prevUrl = this.$router.options.history.state.back;

      if (prevUrl == '/profile') {
        this.$router.back();
      } else {
        this.$router.push({
          name: 'Booking',
          params: {
            publicApiKey: this.reservation.point.vendor.public_api_key
          },
          query: {
            pointId: this.reservation.point.id
          }
        })
      }
    },

    onSuccess() {
      this.$router.push({
        name: 'Booking',
        params: {
          publicApiKey: this.reservation.point.vendor.public_api_key
        },
        query: {
          pointId: this.reservation.point.id
        }
      })
    }
  }
}
</script>

<style lang="scss">
.canceling-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>