import ReservationModel from "@/models/ReservationModel";
import ClientModel from "@/models/ClientModel";
import reservationApi from "@/apis/ReservationApi";
import clientLocalStorage from "@/stores/ClientLocalStorage";
import authService from "@/services/AuthService";
import userService from "@/services/UserService";


const reservationService = {
  async createNewReservation() {
    const reservation = new ReservationModel();

    if (authService.isAuthenticated()) {
      // Если пользователь авторизован берем данные из пользователя
      let user = await userService.getSessionUser();
      reservation.client = new ClientModel().pushPayload({
        name: user.full_name,
        phone: user.phone,
        email: user.email
      });
    } else {
      // Если нет, из сохраненных в локал сторедже
      reservation.client = new ClientModel().pushPayload(clientLocalStorage.getClient());
    }

    return reservation;
  },

  async saveReservation(reservation, captchaToken) {
    return await reservationApi.saveReservation(reservation, captchaToken);
  },

  async getReservationByCode(reservationCode) {
    return await reservationApi.getReservationByCode(reservationCode);
  },

  async cancelReservation(reservationCode, clientPhone) {
    return await reservationApi.cancelReservation(reservationCode, clientPhone);
  },

  async confirmReservation(reservationCode, clientPhone) {
    return await reservationApi.confirmReservation(reservationCode, clientPhone);
  },

  async getUserReservations() {
    return await reservationApi.getUserReservations();
  },

  getPaymentUrl(reservationCode) {
    const HOST = process.env.VUE_APP_MAIN_HOST;
    return `${HOST}/reservations/${reservationCode}/online-payment`
  }
}

export default reservationService;