<template>
  <div
    class="profile"
    v-if="user"
  >
    <div
      class="user-card"
    >
      <div class="user-name">
        {{user.full_name}}
      </div>
      <span
        class="logout"
        @click="signOut"
      >
        {{ $t('cmp.profile.logout') }}
      </span>
    </div>

    <div class="reservations-list">
      <div
        v-if="reservations.length == 0"
        class="empty-list"
      >
        {{ $t('cmp.profile.emptyList') }}
      </div>

      <div
        class="item"
        v-for="reservation in reservations"
        :key="reservation.id"
        @click="() => goToReservation(reservation)"
      >
        <div class="left-col">
          <div class="time">
            {{moment(reservation.start_date).format('lll')}}
          </div>
          <div class="point-name">
            {{reservation.point.name}}
          </div>
        </div>
        <div class="right-col">
          <img src="@/ui/assets/icons/chevron-right.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import authService from '@/services/AuthService'
import userService from '@/services/UserService'
import reservationService from '@/services/ReservationService'

export default {
  name: 'Profile',
  props: {
    onSignOut: Function,
    goToReservation: Function,
  },
  data() {
    return {
      user: null,
      reservations: [],
      moment,
    }
  },
  mounted() {
    this.loadUser();
    this.loadUserReservations();
  },
  methods: {
    async loadUser() {
      try {
        this.user = await userService.getSessionUser();
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error'
        });
      }
    },

    async loadUserReservations() {
      try {
        this.reservations = await reservationService.getUserReservations();
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error'
        });
      }
    },

    signOut() {
      authService.signOut();
      this.onSignOut();
    }
  }
}
</script>

<style lang="scss">
.profile {
  .user-card {
    padding: 30px;
    text-align: center;

    .user-name {
      font-size: 18px;
    }

    .logout {
      color: $primary;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .reservations-list {
    .empty-list {
      text-align: center;
      font-size: 14px;
      padding: 20px 0;
    }

    & > .item {
      cursor: pointer;
      display: flex;
      background-color: white;
      border-bottom: 1px solid $border;
      padding: 15px;

      .left-col {
        flex-grow: 1;
        // display: flex;

        .time {
          font-size: 14px;
          color: $grayText;
        }
      }

      .right-col {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        padding: 0 5px;
      }
    }
  }
}
</style>