<template>
  <div class="points-step">
    <yandex-map
      :coords="[mapCenter.lat, mapCenter.lng]"
      :zoom="10"
      :settings="{lang: ymapLocale}"
    >
      <ymap-marker
        v-for="point in points"
        :key="point.id"
        :markerId="point.id"
        :coords="[point.latitude, point.longitude]"
        @click="openPointCard(point)"
      >
      </ymap-marker>
    </yandex-map>

    <BottomBar
      v-if="selectedPoint"
    >
      <div class="row">
        <div class="col">
          <div class="map-company-card">
            <div class="name">{{selectedPoint.name}}</div>
            <div class="address">{{selectedPoint.address}}</div>
            <TubeStation
              :tubeStation="selectedPoint.tube_station"
            />
          </div>
        </div>
        <div class="col-auto col-md-4 d-flex align-items-center">
          <Button
            :text="$t('buttons.next')"
            :action="selectPoint"
          />
        </div>
      </div>
    </BottomBar>
  </div>
</template>

<script>
import BottomBar from '@/ui/components/BottomBar'
import Button from '@/ui/components/Button'
import TubeStation from '@/ui/components/TubeStation'
import pointService from '@/services/PointService'
import settingsService from '@/services/SettingsService'

const YMAP_LOCALES = {
  ru: 'ru_RU',
  en: 'en_US',
};

const MOSCOW_COORDINATES = {
  lat: 55.7522200,
  lng: 37.6155600,
}

export default {
  name: 'PointsStep',
  props: {
    reservation: Object,
    onSelectPoint: Function,
  },
  components: {
    BottomBar,
    Button,
    TubeStation,
  },

  data() {
    return {
      MOSCOW_COORDINATES,
      ymapLocale: YMAP_LOCALES[settingsService.getLocale()],
      points: null,
      selectedPoint: null,
    }
  },

  mounted() {
    this.loadPoints();
  },

  computed: {
    mapCenter: function () {
      if (!this.points) {
        return MOSCOW_COORDINATES
      }

      const latitudes = this.points.map(point => point.latitude).sort((a, b) => a - b);
      const longitudes = this.points.map(point => point.longitude).sort((a, b) => a - b);

      const maxLatitude = latitudes[latitudes.length - 1];
      const minLatitude = latitudes[0];
      const maxLongitude = longitudes[longitudes.length - 1];
      const minLongitude = longitudes[0];

      return {
        lat: (maxLatitude + minLatitude) / 2,
        lng: (maxLongitude + minLongitude) / 2
      };
    }
  },

  watch: {
    '$i18n.locale': function(newValue, oldValue) {
      // Для перерисовки карты ее нужно перегрузить заново, поэтому перезагружаем всю страницу
      if (newValue != oldValue) {
        window.location.reload();
      }
    }
  },

  methods: {
    async loadPoints() {
      try {
        this.points = await pointService.getPoints();
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error'
        });
      }
    },

    openPointCard(point) {
      this.selectedPoint = point;
    },

    async selectPoint() {
      try {
        const point = await pointService.getPoint(this.selectedPoint.id);
        this.onSelectPoint(point);
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error'
        });
      }
    }
  }
}
</script>

<style lang="scss">
.points-step {
  position: relative;

  .ymap-container {
    height: 800px;
  }

  .bottom-bar {
    .map-company-card {
      .address {
        font-size: 14px;
        margin-bottom: 3px;
      }
    }
  }
}
</style>