import Api from '@/utils/Api';
import PointModel from '@/models/PointModel';

const pointApi = {
  async getPoints() {
    const response = await Api.get('/points');
    return response.data.points.map((pointPayload) => {
      return new PointModel().pushPayload(pointPayload);
    });
  },

  async getPoint(pointId) {
    const response = await Api.get(`/points/${pointId}`);
    return new PointModel().pushPayload(response.data.point);
  }
}

export default pointApi;