<template>
  <div class="cart">
    <div class="info">
      <div class="count">
        {{servicesCount}}
      </div>
      <div class="props">
        <div class="prop duration">
          <img src="@/ui/assets/icons/timer.svg" />
          {{intToDuration(servicesDuration, $i18n.locale)}}
        </div>
        <div class="prop cost">
          <img src="@/ui/assets/icons/wallet.svg" />
          {{servicesCost}} {{currencySign}}
        </div>
      </div>
    </div>
    <button
      class="clear-btn"
      @click="onClear"
    >
      <img src="@/ui/assets/icons/trash.svg" />
    </button>
  </div>
</template>

<script>
import { intToDuration } from '@/utils/Helpers'

export default {
  name: 'Cart',
  props: {
    selectedServices: Array,
    currencySign: String,
    onClear: Function,
  },

  data() {
    return {
      intToDuration
    }
  },

  computed: {
    servicesCount() {
      return this.selectedServices.length;
    },

    servicesDuration() {
      return this.selectedServices.reduce((previousValue, service) => previousValue + service.duration, 0);
    },

    servicesCost() {
      return this.selectedServices.reduce((previousValue, service) => previousValue + service.min_price, 0);
    }
  }
}
</script>

<style lang="scss">
.cart {
  display: flex;

  border: 1px solid $primary;
  border-radius: 6px;
  overflow: hidden;

  .info {
    padding: 3px 10px;
    display: flex;
    align-items: center;

    .count {
      border-radius: 50%;
      background-color: $primary;
      color: white;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
    }

    .prop {
      font-size: 12px;
      display: flex;
      align-items: center;
      min-width: 80px;

      img {
        height: 12px;
        margin-right: 3px;
      }
    }

  }

  .clear-btn {
    background-color: $primary;
    border: none;
    padding: 0 10px;
    cursor: pointer;

    img {
      width: 20px;
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    }
  }
}
</style>