import { createRouter, createWebHistory } from 'vue-router'
import BookingPage from './pages/reservation/BookingPage.vue'
import ProfilePage from './pages/profile/ProfilePage.vue'
import ReservationFailPage from './pages/reservation/ReservationFailPage.vue'
import ReservationPage from './pages/reservation/ReservationPage.vue'
import ReservationSuccessPage from './pages/reservation/ReservationSuccessPage.vue'
import SignInPage from './pages/profile/SignInPage.vue'
import SignUpPage from './pages/profile/SignUpPage.vue'

const routes = [
  {
    path: '/:publicApiKey',
    name: 'Booking',
    component: BookingPage,
  },
  {
    path: '/reservations/:reservationCode',
    name: 'Reservation',
    component: ReservationPage
  },
  {
    path: '/reservations/:reservationCode/success',
    name: 'ReservationSuccess',
    component: ReservationSuccessPage
  },
  {
    path: '/reservations/:reservationCode/fail',
    name: 'ReservationFail',
    component: ReservationFailPage
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfilePage,
  },
  {
    path: '/profile/signin',
    name: 'SignIn',
    component: SignInPage,
  },
  {
    path: '/profile/signup',
    name: 'SignUp',
    component: SignUpPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router