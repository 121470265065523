<template>
  <div
    class="tube-station"
    v-if="tubeStation"
  >
    <div class="tube-line" :style="{backgroundColor: '#' + tubeStation.tube_line.hex_color}">
      <img src="@/ui/assets/icons/metro.svg" />
    </div>
      {{tubeStation.name}}
  </div>
</template>

<script>
export default {
  name: 'TubeStation',
  props: {
    tubeStation: Object,
  }
}
</script>

<style lang="scss">

.tube-station {
  display: flex;
  align-items: center;
  font-size: 14px;

  .tube-line {
    width: 19px;
    height: 19px;
    margin-right: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
