import moment from 'moment';
import Api from '@/utils/Api';
import ReservationModel from '@/models/ReservationModel';

var reservationSerializer = function(reservation) {
  if (reservation.client.email == '') {
    reservation.client.email = null;
  }

  return {
    ...reservation,
    point: reservation.point.id,
    resource: reservation?.resource?.id,
    start_date: moment(reservation.start_date).format('MM-DD-YYYY HH:mm'),
    reservation_services: reservation.reservation_services.map((rs) => {
      return { service: rs.service.id };
    })
  }
}

const reservationApi = {
  async saveReservation(reservation, captchaToken) {
    const response = await Api.post(`/reservations`, {
      data: {
        captcha_token: captchaToken,
        ...reservation.serialize(reservationSerializer)
      }
    });
    return new ReservationModel().pushPayload(response.data.reservation);
  },

  async getReservationByCode(reservationCode) {
    const response = await Api.get(`/reservations/${reservationCode}`);
    return new ReservationModel().pushPayload(response.data.reservation);
  },

  async cancelReservation(reservationCode, clientPhone) {
    await Api.delete(`/reservations/${reservationCode}`, {
      params: {
        client_phone: clientPhone
      }
    });
    return true;
  },

  async confirmReservation(reservationCode, clientPhone) {
    await Api.put(`/reservations/${reservationCode}/confirm`, {
      data: {
        client_phone: clientPhone
      }
    });
    return true;
  },

  async getUserReservations() {
    const response = await Api.get(`/user-reservations`);

    return response.data.reservations.map((reservationPayload) => {
      return new ReservationModel().pushPayload(reservationPayload);
    });
  }
}

export default reservationApi;