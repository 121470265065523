<template>
  <div class="footer">
    <a href="https://profi.guide/">
      <span class="name">{{ $t('cmp.footer.copyright') }}</span>

      <img
        class="logo"
        src="@/ui/assets/images/logo.png"
        alt="ПрофиГид лого"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="scss">
.footer {
  padding: 15px 5px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grayText;
    text-decoration: none;
    font-size: 12px;

    .name {
      margin-right: 10px;
    }
  }
}
</style>