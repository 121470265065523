import authApi from "@/apis/AuthApi";
import userApi from "@/apis/UserApi";
import userSessionStorage from "@/stores/UserSessionStorage";

const authService = {
  async sendCode(email, captchaToken) {
    return await authApi.sendCode(email, captchaToken);
  },

  async signIn(email, code) {
    let jwt = await authApi.signIn(email, code);
    userSessionStorage.saveSession({ jwt });
    return true;
  },

  async signUp(firstName, lastName, phone, email, captchaToken) {
    return await authApi.signUp(firstName, lastName, phone, email, captchaToken);
  },

  signOut() {
    userSessionStorage.saveSession({});
  },

  isAuthenticated() {
    let session = userSessionStorage.getSession();
    return !!session?.jwt;
  },

  async getSessionUser() {
    let session = userSessionStorage.getSession();
    return await userApi.getUserByJWT(session.jwt);
  }
}

export default authService;