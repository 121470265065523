<template>
  <div class="fail-page">
    <FailStep
      :payUrl="getPaymentUrl()"
    />
  </div>
</template>

<script>
import reservationService from '@/services/ReservationService'
import FailStep from '@/ui/components/steps/10.FailStep'

export default {
  name: 'ReservationFailPage',

  components: {
    FailStep
  },

  data() {
    return {
      reservationCode: null,
      isDisableCanceling: null
    }
  },

  methods: {
    getPaymentUrl() {
      return reservationService.getPaymentUrl(this.$route.params.reservationCode)
    }
  }
}
</script>

<style lang="scss">
.success-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>