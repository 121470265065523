<template>
  <div
    class="promotions"
    v-if="promotions.length"
    :style="[promotions.length == 1 ? {justifyContent: 'center'} : {}]"
  >
    <div
      v-for="promotion in promotions"
      :key="promotion.id"
      class="card"
    >
      <div class="cover" :style="{backgroundImage: `url(${promotion.cover.src})`}"></div>
      <div class="text">
        {{promotion.text}}
      </div>
    </div>
  </div>
</template>

<script>
import promotionService from '@/services/PromotionService';

export default {
  name: "Promotions",
  props: {
    point: Object,
  },

  data() {
    return {
      promotions: []
    }
  },

  mounted() {
    this.loadPromotions();
  },

  methods: {
    async loadPromotions() {
      try {
        this.promotions = await promotionService.getPromotions(this.point.id);
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: 'error'
        });
      }
    },
  },
};
</script>

<style lang="scss">
.promotions {
  display: flex;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  padding: 10px;

  & > .card {
    width: 284px;
    background-color: white;
    margin-right: 10px;
    border-radius: 5px;

    .cover {
      width: 284px;
      height: 142px;
      background-size: cover;
      border-radius: 5px 5px 0 0;
    }

    .text {
      padding: 10px;
      font-size: 12px;
    }
  }

  & > .card:last-child {
    margin-right: 0;
  }
}
</style>
