const LOCAL_STORAGE_KEY = 'pg_booking_client';

const clientLocalStorage = {
  saveClient(clientDump) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(clientDump));
  },

  getClient() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
  }
};

export default clientLocalStorage;
