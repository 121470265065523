import Api from '@/utils/Api';
import PointSettingsModel from '@/models/PointSettingsModel';

const pointSettingsApi = {
  async loadSettings(pointId) {
    const response = await Api.get(`/point-settings/${pointId}`);
    return new PointSettingsModel().pushPayload(response.data.point_settings);
  }
}

export default pointSettingsApi;