<template>
  <div class="success-page">
    <SuccessStep/>
  </div>
</template>

<script>
import SuccessStep from '@/ui/components/steps/8.SuccessStep'

export default {
  name: 'ReservationSuccessPage',
  components: {
    SuccessStep
  },

  data() {
    return {
      reservationCode: null,
      reservation: null,
      isDisableCanceling: null
    }
  },

  async mounted() {
    this.parseUrlParams();

    if (this.reservationCode) {
      setTimeout(this.goToReservation, 1000);
    }
  },

  methods: {
    parseUrlParams() {
      this.reservationCode = this.$route.params.reservationCode;
    },

    goToReservation() {
      this.$router.replace({
        name: 'Reservation',
        params: {
          reservationCode: this.reservationCode
        }
      })
    }
  }
}
</script>

<style lang="scss">
.success-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>