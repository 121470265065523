<template>
  <div class="reservation-details">
    <dl>
      <dt>{{ $t('cmp.reservationDetails.date') }}</dt>
      <dd>{{moment(reservation.start_date).format('lll')}}</dd>
    </dl>

    <dl v-if="reservation.resource">
      <dt>{{ $t('cmp.reservationDetails.specialist') }}</dt>
      <dd>{{reservation.resource.name}}</dd>
    </dl>

    <dl>
      <dt>{{ $t('cmp.reservationDetails.contacts') }}</dt>
      <dd>
        {{reservation.client.name}},

        <template v-if="reservation.client.phone">
          {{reservation.client.phone}},
        </template>

        {{reservation.client.email}}
      </dd>
    </dl>

    <dl v-if="reservation.client_note">
      <dt>{{ $t('cmp.reservationDetails.note') }}</dt>
      <dd>
        {{reservation.client_note}}
      </dd>
    </dl>

    <dl v-if="reservation.amount_online">
      <dt>{{ $t('cmp.reservationDetails.amountOnline') }}</dt>
      <dd>
        {{reservation.amount_online}} {{currencySign}}
      </dd>
    </dl>

    <ServicesList
      :isSelectable="false"
      :services="this.reservation.reservation_services.map(rs => rs.service)"
    />
  </div>
</template>

<script>
import moment from 'moment';
import ServicesList from '@/ui/components/ServicesList'


export default {
  name: 'ReservationDetails',
  props: {
    reservation: Object,
  },

  components: {
    ServicesList,
  },

  data() {
    return {
      moment,
      currencySign: this.reservation.point.country.currency.sign
    }
  }
}
</script>

<style lang="scss">
.reservation-details {
  dl {
    margin: 20px;

    dt {
      color: $grayText;
    }
  }
}
</style>
