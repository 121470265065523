import serviceApi from "@/apis/ServiceApi";
import ReservationServiceModel from "@/models/ReservationServiceModel";

const serviceService = {
  async getCategoriesWithServices(pointId) {
    return await serviceApi.getCategoriesWithServices(pointId);
  },

  createReservationServicesFromSerivices(services) {
    return services.map(service => {
      let rs = new ReservationServiceModel()
      rs.service = service;
      rs.price = service.min_price;
      rs.duration = service.duration;
      return rs;
    });
  }
}

export default serviceService;