import Api from '@/utils/Api';
import PromotionModel from '@/models/PromotionModel';

const promotionApi = {
  async getPromotions(pointId) {
    const response = await Api.get('/promotions', {
      params: {
        point_id: pointId
      }
    });
    return response.data.promotions.map((promotionPayload) => {
      return new PromotionModel().pushPayload(promotionPayload);
    });
  }
}

export default promotionApi;