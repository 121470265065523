import Api from '@/utils/Api';
import UserModel from '@/models/UserModel';

const userApi = {
  async getUser() {
    const response = await Api.get(`/users/me`);
    return new UserModel().pushPayload(response.data.user);
  }
}

export default userApi;